import React from "react";
import { Container, Row, Col, FloatingLabel, Form, Button } from "react-bootstrap";
import "components/common/perfectScooter/perfectScooter.scss"


const PerfectScooter = () => {

    return (
        <div className="perfectScooter">
            <Container className="pt-5 mb-5 mt-5 containerScooter">
                <Row className="p-4 mb-5" style={{ background: "#ECF1F4", borderRadius: "30px" }}>
                    <Col md={7}>
                        <div>
                            <h1 className="text-black" style={{ fontWeight: "600" }}>Your own perfect <br />scooter</h1>
                            <div className="form_box">
                                <form>
                                    <Row>
                                        <Col md={6} className="mb-3">
                                            <FloatingLabel controlId="floatingSelect" label="Scooter" className="label">
                                                <Form.Select aria-label="Floating label select example" className="backgroundForm">
                                                    <option>Open this select menu</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </FloatingLabel></Col>
                                        <Col md={6} className="mb-3">
                                            <input type='text' placeholder='Your name' name='name' autoFocus className="form-control backgroundForm" />
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <input type='email' placeholder='Your email' name='name' autoFocus className="form-control backgroundForm" /></Col>
                                        <Col md={6} className="mb-3"><input type='text' placeholder='Your phone' name='name' autoFocus className="form-control backgroundForm" /></Col>
                                        <Col md={6} className="mb-3">  <Button variant="dark" className=" orderButton w-100" type="submit">Order right now</Button></Col>
                                        <Col md={6} className="mb-3"> <button className="payButton w-100 d-flex justify-content-center gap-1"><img src="https://metrorides.theclientdemos.com/assets/images/apple_music.svg" /> Apple pay</button></Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="scooterImage">
                            <img src="https://metrorides.theclientdemos.com/assets/images/cta.png" className="w-90" /></div></Col>
                </Row>
            </Container>
        </div>
    )

}
export default PerfectScooter