import React, { useEffect, useState } from "react";
import { Container,Row,Col,Form} from "react-bootstrap";
import { postMethod } from "services/apiServices";
import url from "services/urls.json";
import { toast } from "react-toastify";


const ForgotPassword=()=>{

    const [email , setEmail]=useState('')
    const [validEmail , setValidEmail]=useState(true)
    const handleChange =(e)=>{
        setEmail(e.target.value)
    }

    const getFormData =(e)=>{
        e.preventDefault()
       forgotPassword(email)
    }

    const forgotPassword=async(email)=>{
        
        let body = {
            "email":email
        }
        await postMethod(url.forgotPassword,body).then((response)=>{
            if(response.status === 200 || response.status === 201){
                toast.success(response.data.response);
            }
            else {
                toast.error(response.data.error)
            }
        })
    }
    useEffect(() => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (email && !emailRegex.test(email)) {
          setValidEmail(false);
        } else {
          setValidEmail(true);
        }
      }, [email]);
   
    return (
        <div>
            <Container>
               <Row>
                <Col style={{textAlign:'center'}}>
                <h1 className="mb-4">Forgot Password</h1>
                <div >
                    <p >Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.</p>
                    </div>
                    </Col>
               </Row>
               <Row className="justify-content-center mt-4">
                <Col md={4}>  <form onSubmit={getFormData}>
                                <div className='mb-3'>
                                    <input type='email' placeholder='Email' name='email' autoFocus  value={email} className="form-control" onChange={handleChange} required />
                                    {!validEmail && <p  className='text-danger pt-2  text-center'>Please enter a valid email address.</p> }
                                </div>
                                <button type='submit' className='btn btn-dark btn-lg submit mb-5 w-100' >Submit</button>
                            </form></Col>
                </Row>  
            </Container>
        </div>
    )
}
export default ForgotPassword