import { React, useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const Reviews = () => {

    const reviews = [
        { id: 1, value: "Reviews from companies" },
        { id: 2, value: "Rider profiles" },
        { id: 3, value: "Feedback from subscribers" }
    ]
    return (
        <div>
            {reviews && reviews.map((data, index) => (
                <div style={{ background: data?.value === "Rider profiles" ? "#FAFAFA" : '' }} className="pt-5 pb-5">
                    <Container className="pt-5 pb-4  mt-5 mb-5" >
                        <Row className="align-items-center ">

                            <Col md={12} >
                                <div className="mt-5 d-flex justify-content-between align-items-center">
                                    <h1 className="text-left" style={{ fontWeight: '600' }}>{data?.value}</h1>
                                    <Button variant="outline-dark" className="mt-4 " style={{border:'2px solid black',fontWeight:'600'}}>All reviews</Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            ))}
        </div>

    )
}
export default Reviews