import { React, useState, useEffect,lazy,Suspense } from "react";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Carousel from 'react-bootstrap/Carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PublicTransport from "./PublicTransport/PublicTransport";
import ModerApp from "./ModerApp/ModerApp";
import Reviews from "./Reviews/Reviews";
// import Questions from "./Questions/Questions";
import "pages/Home/Home.scss";
import { getMethodWithToken, getMethod } from "services/apiServices";
import url from 'services/urls.json';
import { useDispatch } from "react-redux";
import { ClearTokenRedirectLogin } from "context/context";
import { useNavigate } from "react-router-dom";


const Home = () => {
    const Questions = lazy(()=>import("./Questions/Questions"));
    
    const array = new Array(3).fill(null);
    const navigate = useNavigate()
    const [selectedId, setSelectedId] = useState(4);

    const handleNavProducts = (id) => {
        setSelectedId(id);
    };

    const [activeIndex, setActiveIndex] = useState(0);
    const [id, setId] = useState('')
    const [order, setOrder] = useState(0)
    const [productList, setProductList] = useState([])
    const [productId, setProductId] = useState('')

    const handleSelect = (index) => {
        setActiveIndex(index);
    };
    useEffect(() => {
        handleProductList()
    }, [])

    const handleProductList = async () => {
        let apiUrl = `${url.buy}?catagory=scooter&paginate=false&page_limit=0`;
        let response = localStorage.getItem('token') ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        if (response.status === 200 || response.status === 201) {
            setProductList(response.data)
        }
        else if (response.status === 401 || response.status === 403) {
            ClearTokenRedirectLogin(navigate)
        }
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);
   
    return (
        <div style={{ fontFamily: "Manrope, sans-serif" }} className="Home">
            <Container fluid >
                <div >
                    <Carousel>
                        {array.length > 0 && array.map((data, index) => (
                            <Carousel.Item>
                                <img src="https://metrorides.theclientdemos.com/assets/images/screen.svg" className="d-block w-100" />

                            </Carousel.Item>
                        ))}

                    </Carousel>
                </div>
                <div className="mt-3 text-center">
                    <Container>
                        <Row>
                            <Col md={12}> <h1 style={{ fontWeight: '600' }}>What’s Your Model?</h1></Col>
                            <Col md={12}>
                                <div className=" d-flex flex-column justify-content-center">
                                    <Navbar data-bs-theme="dark" className=" d-flex flex-column justify-content-center" gap={5}>
                                        <Nav className="d-md-flex d-block justify-content-center" gap={5}>
                                            {productList && productList.length > 0 && productList.map((data, index) => (
                                                <Nav.Link href="#" className="text-dark">
                                                    <button className="d-flex align-items-center  navButton" gap={5} style={{

                                                        padding: selectedId === data?.id ? '5px 17px' : '5px 17px', backgroundColor: selectedId === data.id ? '#4579F5' : 'transparent',
                                                        border: 'none', borderRadius: '4px',

                                                    }} onClick={() => handleNavProducts(data?.id)}>
                                                        <img src={data?.images[0]?.original} className="me-2" style={{ width: '30px', height: '30px', background: 'white', borderRadius: '5PX' }} />
                                                        <span className={`title ${selectedId === data?.id ? 'selected' : ''}`} >
                                                            {data.title.trim().split(/\s+/).slice(0, 3).join(' ')}
                                                        </span>

                                                    </button></Nav.Link>
                                            ))}

                                        </Nav>
                                    </Navbar>
                                    <Row>
                                        <Col md={6} className="justify-content-center pt-2"><div className="carouselBuy" >
                                            <Carousel activeIndex={activeIndex} onSelect={handleSelect} prevIcon={<ArrowBackIosIcon style={{ color: 'black' }} />}
                                                nextIcon={<ArrowForwardIosIcon style={{ color: 'black' }} />} >
                                                {productList && productList.length > 0 && productList.map((item, itemIndex) => (
                                                    item?.id === selectedId && item?.images.length > 0 && item.images.map((data, dataIndex) => (
                                                        <Carousel.Item key={`${itemIndex}-${dataIndex}`} className="text-center">
                                                            <img src={data?.original} className="d-block mx-auto" alt={`Slide ${itemIndex}-${dataIndex}`} style={{ width: '20rem', height: '25rem' }} />
                                                        </Carousel.Item>
                                                    ))
                                                ))}
                                            </Carousel>

                                            <div className="d-flex justify-content-center mt-3">
                                                {productList && productList.length > 0 && productList.map((data, index) => (
                                                    data?.id === selectedId && data?.images && data?.images.length > 0 && data?.images.map((image, index) => (
                                                        <img
                                                            key={index}
                                                            src={image?.original}
                                                            alt={`Indicator ${index}`}
                                                            className={`indicator-image ${activeIndex === index ? 'active' : ''}`}
                                                            onClick={() => handleSelect(index)}
                                                            style={{ cursor: 'pointer', width: '55px', height: '55px', padding: '10px', objectFit: 'cover', margin: '0 5px', border: (activeIndex === index) ? '1px solid #4579F5' : 'none', borderRadius: '15%' }}
                                                        />
                                                    ))
                                                ))}

                                            </div>
                                        </div></Col>
                                        {productList && productList.length > 0 && productList.map((data, index) => (
                                            data?.id === selectedId && (
                                                <Col md={6} ><div className="product_description" >
                                                    <Stack direction="horizontal" className="align-items-baseline justify-content-between">
                                                        <div> <h3 style={{ fontWeight: '600' }} className="scooterName">{data?.title.trim().split(/\s+/).slice(0, 3).join(' ')}</h3></div>
                                                        <div className="d-flex justify-content-between align-items-baseline gap-1 ">   <Button variant="dark" className="mt-4 rounded-5">For the Urban Commuter</Button><span className="fw-bold">4/5</span></div>

                                                    </Stack>
                                                    <div>
                                                        <Row className="align-items-center  mt-5 text-start" >
                                                            {/* <Col md={6} className="attributesLabel d-none d-md-block">
                                                                {data?.attributes && data?.attributes.length > 0 && data?.attributes.map((brand, index) => (
                                                                    <div className="d-flex align-items-start gap-2 ">
                                                                        <WatchLaterOutlinedIcon style={{ fontSize: '25px' }} />
                                                                        <p className="fw-bold">{brand?.name}</p>
                                                                    </div>
                                                                ))}
                                                            </Col> */}

                                                            {/* <div> */}
                                                            <Col xs={12} lg={12} className="px-3 pt-3 pb-3 mt-3 mb-2 ">
                                                                {data?.attributes && data?.attributes.length > 0 && data?.attributes.map((item, index) => (
                                                                    <div className="justify-content-between align-items-baseline  d-flex">
                                                                        <div className="d-flex align-items-center gap-2 ">
                                                                            <img src={item?.icon} style={{ width: '15px', height: '15px', color: '#c5c5c5' }} />
                                                                            <p className="brandName mb-0">{item?.name}</p>
                                                                        </div>
                                                                        <div  gap={2} className="align-items-baseline pb-3 justify-content-end values d-sm-flex d-block">
                                                                            <div className="mb-0 mx-2 brandValue">{item?.value.trim().split(/\s+/).slice(0, 2).join('')}</div>
                                                                            <div className="d-flex gap-2">
                                                                                <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" />
                                                                                <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" />
                                                                                <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" />
                                                                                <img src="https://metrorides.theclientdemos.com/assets/images/gry.svg" />
                                                                                <img src="https://metrorides.theclientdemos.com/assets/images/gry.svg" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                            {/* </div> */}

                                                        </Row>
                                                    </div>
                                                </div>
                                                    <Stack direction="vertical" gap={3} className="productButtons w-100">
                                                        <Button variant="outline-dark" className=" w-100 fw-bold CartButton pe-4 ps-4 pt-2 pb-2" style={{borderRadius:'25px'}}>Buy / ${data?.price_incl_tax}</Button>
                                                        <Button variant="dark" className=" w-100 fw-bold rentButton pe-4 ps-4 pt-2 pb-2" style={{borderRadius:'25px'}}>Rent / ${data?.rent}</Button>
                                                    </Stack>
                                                </Col>)
                                        ))}

                                    </Row>
                                </div>

                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row className="mt-5">
                            <Col md={12} className="mb-4"> <h1 style={{ fontWeight: '600', textAlign: 'left' }}>What do we offer?</h1></Col>
                            <Col md={6}>
                                <img src="https://metrorides.theclientdemos.com/assets/images/i6.png" className="w-100 mt-3" style={{ height: '510px' }} /></Col>
                            <Col md={6}>
                                <Row>
                                    <Col md={12}> <img src="https://metrorides.theclientdemos.com/assets/images/i7.png" className="w-100 mt-3" style={{ height: '244px' }} /></Col>
                                    <Col md={12}>  <img src="https://metrorides.theclientdemos.com/assets/images/i7.png" className="w-100 mt-4" style={{ height: '244px' }} /></Col>
                                </Row>

                            </Col>

                        </Row>
                    </Container>
                    <div style={{ background: "#FAFAFA", padding: '0' }}>
                        <PublicTransport />
                        <ModerApp />
                    </div>
                    <div>
                        <Reviews />
                    </div>
                    <Suspense fallback={<div>loading---</div>}>
                    <div>
                        <Questions />
                    </div>
                    </Suspense>
                   
                </div>
            </Container>
            <div className="mt-5 pt-5">
                <PerfectScooter />

            </div>
        </div>
    )
}

export default Home