import { SIGN_IN, SIGN_IN_ERROR, SIGN_IN_SUCCESS } from "./constant";
import { postMethod } from "services/apiServices";
import url from 'services/urls.json';
import { takeLatest, put } from "redux-saga/effects";
import { ClearTokenRedirectLogin } from "context/context";

function* signApi(action) {

    try {
        const response = yield postMethod(url.signIn, action.data)
        if (response.status === 200 || response.status === 201) {
            yield put({ type: SIGN_IN_SUCCESS, payload: response })

            if (action.external && action.external.run === true) {
                action.external.handleFunction(response)
            }

        }
        else {
            yield put({ type: SIGN_IN_ERROR, payload: response })
            if(response.status === 401 || response.status === 403){
                if (action.external && action.external.toast===true) {
                    action.external.handleToastError(response);
                }
                ClearTokenRedirectLogin(action.external.navigate)
              }
             
        }
    }
    catch (error) {
        yield put({ type: SIGN_IN_ERROR, payload: error })
    }
}

function* signSaga() {
    yield takeLatest(SIGN_IN, signApi)
}

export default signSaga;