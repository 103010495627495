import { React, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../SignIn/SignIn.scss';
import Button from 'react-bootstrap/Button';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigate } from 'react-router-dom';
import { postMethod } from 'services/apiServices';
import url from 'services/urls.json';
import { setToken } from 'services/common';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { signupCallApi } from 'store/SignUp/action';
import { SIGN_IN } from 'store/signIn/constant';
import signCallApi from 'store/signIn/action';

const SignIn = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [validEmail, setValidEmail] = useState(true)

    const handleEmailChange = (e) => {

        setEmail(e.target.value)

    };
    const handlePasswordChange = (e) => {

        setPassword(e.target.value)
    };
    const getFormData = (e) => {
        let body = {
            username: email,
            password: password
        }
        e.preventDefault()
        dispatch(signCallApi(SIGN_IN, body, {
            run: true,
            handleFunction: (res) => handleSuccess(res),
            toast:true,
            handleToastError:(res)=>handleFailure(res)
            // handleToastError:(res)=>toast.error(res?.data?.non_field_errors?.[0])
        }))
       
    }


    const handleSuccess = (res) => {
        localStorage.setItem("token", res.data.token)
        console.log('54')
        toast.success("Signin successfully !")
        navigate('/')
    }
    const handleFailure = (res)=>{
        console.log(res?.data ,"58")
        toast.error("Inalid Login")
        localStorage.removeItem('basektId')
        navigate('/SignIn')
    }
    useEffect(() => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (email && !emailRegex.test(email)) {
            setValidEmail(false);
        } else {
            setValidEmail(true);
        }
    }, [email]);

    return (
        <div className='SignIn'>
            <Container className='mt-3 mb-5 pt-3 pb-5'>
                <Row>
                    <Col md={4} className='mx-auto text-center'>
                        <div>
                            <h1 className='mb-4'>Sign In</h1>
                        </div>
                        <div className='form_box' >
                            <form onSubmit={getFormData}>
                                <div className='mb-3'>
                                    <input type='email' placeholder='Email' name='email' autoFocus value={email} className="form-control" onChange={handleEmailChange} required />
                                    {!validEmail && <p className='text-danger pt-2 text-start' style={{ fontSize: '12px' }}>Please enter a valid email address.</p>}
                                </div>
                                <div className='mb-3 relative'>
                                    <input type={showPassword ? 'text' : 'password'} placeholder='Password' name='password' autoFocus value={password} className="form-control" onChange={handlePasswordChange} required />
                                    <span className="passwordIcon cursor-pointer" onClick={() => setShowPassword(!showPassword)} >{showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}</span>
                                </div>
                                <div>
                                    <p onClick={() => navigate("/ForgotPassword")} className='text-left text-decoration-underline text-primary mb-3 cursor-pointer'>Forgot password?</p>
                                </div>
                                <button type='submit' className='btn btn-dark btn-lg submit mb-5 w-100' >Sign In</button>
                                <div className='text-center'>
                                    <p className='mb-0 fw-500' style={{ fontSize: '14px' }}> Still don’t have an account?  </p>
                                    <p onClick={() => navigate("/SignUp")} className='text-center  mb-3 fw-600 cursor-pointer' style={{ fontSize: '14px', color: '#0d6efd' }}>Sign up</p>
                                </div>

                            </form>
                        </div>


                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default SignIn