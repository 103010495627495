import { COMMON_DETAILS } from "./constant";

const commonCallApi = (type, data, external = {}) => {
    switch (type) {
        case COMMON_DETAILS:
            return {
                type: type,
                data: data,
                external: external
            }
        default:
            return {
                type: type,
            }
    }
}
export default commonCallApi;