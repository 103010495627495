import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button, Menu, MenuItem } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import '../Navbar/Navbar.scss';
import { Stack } from 'react-bootstrap';
import { getToken } from 'services/common';
import { postMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import userCallApi from 'store/user/action';
import { USER } from 'store/user/constant';
import cartCallApi from 'store/cart/action';
import { CART } from 'store/cart/constant';
import commonCallApi from 'store/common/action';
import { COMMON_DETAILS } from 'store/common/constant';
import { ClearTokenRedirectLogin, ClearTokenRedirectLogout } from 'context/context';
import storage from "redux-persist/lib/storage";
import { persistor } from "store";


const drawerWidth = 240;
const navItems = [
  { name: 'Buy', icon: "", path: "Buy" },
  { name: 'Store', icon: "", path: "Store" },
  { name: 'Compare', icon: "", path: "Compare" },
  { name: 'Metro+', icon: "", path: "Metro" },
  { name: 'Our Vision', icon: "", path: "OurVision" },
  { name: 'Cart', icon: "https://metrorides.theclientdemos.com/assets/images/cart.svg", path: "Cart" }
];
const authItems = [{ name: 'Sign In', path: "SignIn" }, { name: 'Sign Up', path: "SignUp" }];
const userMe = [{ name: 'MyAccount', path: '' }];


export default function DrawerAppBar(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  // const location=useLocation();
  const { window } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [token, setToken] = useState('');
  const [userDetail, setUserDetail] = useState([])

  const selectedProduct = useSelector(state => state.commonReducer?.cart?.cart);

  React.useEffect(() => {
    if (!isMobile) {
      setMobileOpen(false);
    }
  }, [isMobile]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const location=useLocation()
  const handleLogOut = async () => {
    await postMethodWithToken(url.logOut).then((response) => {
      if (response.status === 200 || response.status === 201) {
        // localStorage.removeItem("token")
        setToken('');
        handleClose();
        // navigate("/Buy");
        // localStorage.removeItem('basektId')
        ClearTokenRedirectLogout(navigate)

      }
      else {
        handleClose()
        setToken('');
        localStorage.removeItem("token")
        navigate("/SignIn")
      }
    })

  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handlePage = (path) => {

    navigate(`/${path}`);
  };
  const handleHomePage = () => {

    navigate('/')
  }
  const handleUser = () => {

    dispatch(userCallApi(USER, {}, {
      run: true,
      handleFunction: (res) => handleUserDetail(res)
    }))

  }
  const handleUserDetail = (res) => {
    setUserDetail(res?.data)
    // localStorage.setItem('basektId', res?.data?.basket_id);
    handleCart(res?.data?.basket_id);
  }

  const handleCart = (basketId) => {
    dispatch(cartCallApi(CART, {}, {
      url:basketId===null?`ecom/baskets/`:`ecom/baskets/${basketId}/lines`,
      run: true,
      handleFunction: (res) => handleSuccess(res)
    }))
  };

  const handleSuccess = (res) => {
    dispatch(commonCallApi(COMMON_DETAILS, { cart: res?.data?.[0]?.total_quantity}, { run: true }))
  };




  useEffect(() => {
    if (localStorage.getItem('token')) {
      handleUser();
    }
  }, [localStorage.getItem('token')])

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} >
      <Typography variant="h6" sx={{ my: 2 }}>
        <img src="https://metrorides.theclientdemos.com/assets/images/logo.svg" alt="Logo" onClick={handleHomePage} />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ justifyContent: 'center', color: 'black' }} onClick={() => handlePage(item?.path)}>

              {selectedProduct > 0 && item?.name == 'Cart' &&
                <div className='absolute carticonmobile'>{selectedProduct}</div>}

              <img src={item.icon} className='me-0' />
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
        {localStorage.getItem("token") ? (

          userMe.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton sx={{ justifyContent: 'center', color: 'black' }} onClick={() => handlePage(item?.path)}>
                <ListItemText primary={item.name} secondary={userDetail?.first_name} />
              </ListItemButton>
            </ListItem>
          ))

        ) : (
          authItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton sx={{ justifyContent: 'center', color: 'black' }} onClick={() => handlePage(item.path)}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))
        )}

      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className="navbar">
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar component="nav" sx={{ background: 'transparent', boxShadow: 'none', color: 'black' }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                <img src="https://metrorides.theclientdemos.com/assets/images/logo.svg" alt="Logo" onClick={handleHomePage} />
              </Typography>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, justifyContent: 'start', paddingTop: '15px' }}>
              {navItems.map((item) => (
                <Box key={item.name} sx={{ display: 'flex', alignItems: 'center', mx: 1, gap: '10px' }}>
                  <Stack direction="horizontal" gap={0}>
                    {selectedProduct > 0 && item?.name === 'Cart' &&
                      <div className='absolute carticon'>{selectedProduct}</div>}
                    {item.icon && <img src={item.icon} alt={`${item.name} icon`} className='navIcon me-0' />}
                    <Button
                      sx={{
                        color: '#fff',
                        textTransform: 'none',
                        fontSize: '1rem',

                      }}
                      onClick={() => handlePage(item.path)}
                      className='navItems'
                    >
                      {item.name}
                    </Button>
                  </Stack>

                </Box>
              ))}
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, paddingTop: '15px' }}>
              {localStorage.getItem("token") ? (
                userMe.map((item) => (
                  <div>
                    <Button
                      onClick={handleClick}
                      sx={{ color: '#fff', textTransform: 'none', fontSize: '1rem' }}
                      className='navItems'
                    >
                      {userMe[0].name} ({userDetail?.first_name})
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        sx: {
                          maxHeight: 48 * 4.5, // Adjust based on menu height
                          width: '20ch',
                        },
                      }}
                    >
                      <MenuItem onClick={handleClose}>Dashboard</MenuItem>
                      <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                    </Menu>
                  </div>

                ))
              ) : authItems.map((item) => (
                <Button key={item.name} sx={{ color: '#fff', textTransform: 'none', fontSize: '1rem' }} onClick={() => handlePage(item.path)} className='navItems'>
                  {item.name}
                </Button>
              ))}

            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, color: 'black' },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar />
          <Typography>
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
