import { React, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

const PublicTransport = () =>{


    return (
        <div style={{fontFamily:"Manrope, sans-serif"}}>
              <Container className="pt-5 pb-4  mt-5 mb-5" >
                        <Row className="mt-5 align-items-center">
                           
                            <Col md={6} className="text-left mb-3">
                               <h1 style={{fontWeight:'600'}}>Don't bare public transportation. <br/> Enjoy the outdoors and explore your city</h1>
                               <p>Having fun cruising the streets at 20+Mph</p>
                               </Col>
                            <Col md={6}>
                               <img src="https://metrorides.theclientdemos.com/assets/images/a3.png" className="w-100"/>

                            </Col>

                        </Row>
                    </Container>
        </div>
    )
}
export default PublicTransport