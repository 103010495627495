import { all, fork } from "redux-saga/effects";
import singUpSaga from "./SignUp/saga";
import getCountriesSaga from "./Countries/saga";
import userSaga from "./user/saga";
import cartSaga from "./cart/saga";
import checkoutSaga from "./checkout/saga";
import signSaga from "./signIn/saga";
import paymentIntentSaga from "./paymentIntent/saga";

const rootSaga = function* () {
    yield all([
        fork(singUpSaga),
        fork(getCountriesSaga),
        fork(checkoutSaga),
        fork(userSaga),
        fork(cartSaga),
        fork(signSaga),
        fork(paymentIntentSaga)
    ]);
};

export default rootSaga;