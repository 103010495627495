import storage from "redux-persist/lib/storage";
import { persistor } from "store";

export function ClearTokenRedirectLogin(navigate) {
    localStorage.removeItem("token");
    // localStorage.removeItem("registerToken");
    storage.removeItem('persist:store');
    persistor.pause();
    persistor.flush().then(() => {
       return persistor.purge();
    });
    navigate('/SignIn');
    window.location.reload();
 }
 export function ClearTokenRedirectLogout(navigate) {
    localStorage.removeItem("token");
    // localStorage.removeItem("registerToken");
    storage.removeItem('persist:store');
    persistor.pause();
    persistor.flush().then(() => {
       return persistor.purge();
    });
    navigate('/Buy');
    window.location.reload();
 }

