import { React, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../SignIn/SignIn.scss';
import Button from 'react-bootstrap/Button';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigate } from 'react-router-dom';
import url from 'services/urls.json'
import { postMethod } from 'services/apiServices';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { signupCallApi } from 'store/SignUp/action';
import { SIGN_UP } from 'store/SignUp/constant';
const SignIn = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [userName , setUserName]=useState('')
    const [email , setEmail]=useState('')
    const [password , setPassword]=useState('')
    const [confirmPassword , setConfirmPassword]=useState('')
    const [validEmail , setValidEmail]=useState(true)
    const [error, setError] = useState(''); 
    const [pwdError, setPwdError] = useState('');

    const signUpData = useSelector(state=>state?.signUpReducer?.payload?.data)
   
    const handleNameChange = (e) => {

       setUserName(e.target.value)
    };
    const handleEmailChange = (e) => {
       
        setEmail(e.target.value)
     };

     const validatePassword = (pwd) => {
        if (pwd.length < 8) {
          return 'Password must be at least 8 characters long with at least one digit , one special character and one Capital character';
        }
        return '';
      };
      const validateConfirmPassword = (pwd)=>{
        if(password!== pwd){
            return 'confirm password not same as password';
        }
      }
     const handlePasswordChange = (e) => {
        
        setPassword(e.target.value)
        setError(validatePassword(e.target.value));
     };
     const handleConfirmPasswordChange = (e) => {
       
        setConfirmPassword(e.target.value)
        setPwdError(validateConfirmPassword(e.target.value))
     };
    const getFormData = (e) => {
        e.preventDefault()
        let body = {
            name: userName,
            email: email,
            password1: password,
            password2: confirmPassword
        }
        register(body)
    }

    const register = async (body) => {
        dispatch(signupCallApi(SIGN_UP,body,{run:true,
             handleFunction:(res)=>navigate("/SignIn")}));
    }

    // const handleSuccess = (res)=>{
    //     console.log(res)
          
    // }

    useEffect(() => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (email && !emailRegex.test(email)) {
          setValidEmail(false);
        } else {
          setValidEmail(true);
        }
      }, [email]);

    return (
        <div className='SignIn'>
            <Container className='mt-1 mb-5 pt-1 pb-5'>
                <Row>
                    <Col md={4} className='mx-auto text-center'>
                        <div>
                            <h1 className='mb-4'>Sign Up</h1>
                        </div>
                        <div className='form_box' >
                            <form onSubmit={getFormData}>
                                <div className='mb-3'>
                                    <input type='text' placeholder='Enter Name' name='name' autoFocus value={userName} className="form-control" onChange={handleNameChange} required />
                                </div>
                                <div className='mb-3'>
                                    <input type='email' placeholder='Email' name='email' autoFocus value={email} className="form-control" onChange={handleEmailChange} required />
                                    {!validEmail && <p  className='text-danger pt-2 text-left' style={{fontSize:'12px'}}>Please enter a valid email address.</p> }
                                </div>
                                <div className='mb-3 relative'>
                                    <input type={showPassword ? 'text' : 'password'} placeholder='Password' name='password' autoFocus value={password} className="form-control" onChange={handlePasswordChange} required />
                                    <span className="passwordIcon cursor-pointer" onClick={() => setShowPassword(!showPassword)} >{showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}</span>
                                    {error && <p  className='text-danger pt-2 text-left' style={{fontSize:'12px'}}>{error}</p>}
                                </div>

                                <div className='mb-3 relative'>
                                    <input type='password' placeholder='Confirm Password' name='Confirmpassword' autoFocus value={confirmPassword} className="form-control" onChange={handleConfirmPasswordChange} required />
                                    {pwdError && <p className='text-danger pt-2 text-left' style={{fontSize:'12px'}}>{pwdError}</p>}
                                </div>
                                <div>
                                    <p onClick={() => navigate("/ForgotPassword")} className='text-left text-decoration-underline text-primary mb-3 cursor-pointer'>Forgot password?</p>
                                </div>
                                <button type='submit' className='btn btn-dark btn-lg submit mb-4 w-100 cursor-pointer' >Sign Up</button>
                                <div className='text-center'>
                                    <p className='mb-0 fw-500' style={{ fontSize: '14px' }}>  By clicking “Sign up”, you agree  <br /> to the Terms of Service  </p>
                                </div>
                                <div className='text-center mt-5'>
                                    <p className='mb-0 fw-500' style={{ fontSize: '14px' }}>Already have an account?</p>
                                    <p onClick={() => navigate("/SignIn")} className='text-center  mb-3 fw-600 cursor-pointer' style={{ fontSize: '14px', color: '#0d6efd' }}>Sign In</p>
                                </div>

                            </form>
                        </div>


                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default SignIn