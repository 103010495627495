import React, { useEffect, useState } from "react";
import { Container, Row, Col, Stack, Button } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import "pages/Buy/Buy.scss";
import url from 'services/urls.json';
import { getMethod, getMethodWithToken, postMethodWithToken } from "services/apiServices";
import { useNavigate } from "react-router-dom";
import cartCallApi from "store/cart/action";
import { CART } from "store/cart/constant";
import commonCallApi from "store/common/action";
import { COMMON_DETAILS } from "store/common/constant";
import { useDispatch, useSelector } from "react-redux";
import userCallApi from "store/user/action";
import { USER } from "store/user/constant";
import { toast } from "react-toastify"
import { ClearTokenRedirectLogin } from "context/context";
const Buy = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeIndextwo, setActiveIndextwo] = useState(0);
    const [id, setId] = useState('')
    const [order, setOrder] = useState(0)
    const [productList, setProductList] = useState([])
    const [productId, setProductId] = useState('')
    const [firstCarousel, setFirstCarousel] = useState('')
    const [carouselItem, setCarouselItem] = useState([]);
    const [activeIndices, setActiveIndices] = useState({});


    useEffect(() => {
        buy()
    }, []);

    const handleProductId = (id) => {
        setProductId(id)
    };

    const buy = async () => {
        let apiUrl = `${url.buy}?catagory=scooter&paginate=false&page_limit=0`;
        let response;
        response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        if (response.status === 200 || response.status === 201) {
            setProductList(response.data)
            const allCarouselIndex = response?.data?.length > 0 && response.data.map((item, index) => {
                // index === 0 ? setFirstCarousel(item?.id) : index === 1 ? setSecondCarousel(item?.id) : ''
                return (
                    { "carouselId": item?.id }
                )

            });
            setCarouselItem(allCarouselIndex);
        }
        else if (response.status === 401 || response.status === 403) {
            ClearTokenRedirectLogin(navigate)
        }
    }

    console.log(carouselItem)
    const handleAddCart = async (product) => {

        let body = {
            "url": product,
            "quantity": 1
        }

        await postMethodWithToken(url.addCart, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                const basket = response.data.lines.split('/api/v1/')[1]
                toast.success("Item Added to cart")
                dispatch(userCallApi(USER, {}, {
                    run: true,
                    handleFunction: (res) => handleCartCount(res?.data?.basket_id)
                }))

                localStorage.setItem('cartAPI', basket)
                localStorage.setItem("clearAllCart", response.data.url)
            }
            else if (response.status === 406) {
                toast.error("Out Of Stock")
            }
        })

    }
    const handleCart = (basketId) => {
        dispatch(cartCallApi(CART, {}, {
            url: `ecom/baskets/${basketId}/lines`,
            run: true,
            handleFunction: (res) => handleCartCount(res)
        }))
    };
    const handleCartCount = (basketId) => {
        dispatch(cartCallApi(CART, {}, {
            url: `ecom/baskets/${basketId}/lines`,
            run: true,
            handleFunction: (res) => handleSuccess(res)
        }))
    };

    const handleSuccess = (res) => {
        dispatch(commonCallApi(COMMON_DETAILS, { cart: res?.data?.[0]?.total_quantity }, { run: true }))
    };

    const handleSelect = (index, id) => {
        setActiveIndices((prevIndices) => ({
            ...prevIndices,
            [id]: index,
        }));
    };

    return (
        <div className="Buy">
            <Container className="pt-1 mt-1 mb-5">
                <Row>
                    <Col md={12}>
                        <div>
                            <h1 className="text-black fw-700">Which scooter is right for you?</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {productList && productList.length > 0 && productList.map((data, index) => (
                        <Col md={6} key={data?.id}>
                            <div className="mt-4 p-3">
                                <div className="d-flex justify-content-center align-items-center mb-5 gap-3">
                                    {data?.attributes.map((attribute, index) => {
                                        
                                        if (
                                            attribute?.name === "Battery Capacity" ||
                                            attribute?.name === "Charging Time" ||
                                            attribute?.name === "Top Speed"
                                        ) {
                                            return (
                                                <Stack
                                                    key={index}
                                                    direction="horizontal"
                                                    gap={1}
                                                    style={{ fontWeight: '600' }}
                                                    className="attributes"
                                                >
                                                    <img
                                                        src={attribute?.icon}
                                                        alt={attribute?.name}
                                                        className="w-4"
                                                    />
                                                    <p className="mb-0 attributeText" style={{fontSize:'14px'}}>{attribute?.value}</p>
                                                </Stack>
                                            );
                                        }
                                        return null; 
                                    })}

                                   
                                </div>
                            </div>
                            <div className="carouselBuy">
                                <Carousel
                                    activeIndex={activeIndices[data?.id] || 0}
                                    onSelect={(index) => handleSelect(index, data?.id)}
                                    prevIcon={<ArrowBackIosIcon style={{ color: 'black' }} />}
                                    nextIcon={<ArrowForwardIosIcon style={{ color: 'black' }} />}
                                >
                                    {data?.images && data?.images.length > 0 && data?.images.map((item, index) => (
                                        <Carousel.Item key={index}>
                                            <img src={item?.original} className="d-block mx-auto w-75" alt={`Slide ${index}`} style={{ height: '450px' }} />
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                                <div className="text-center fw-bold pt-4 pb-2"><h3>{data?.title}</h3></div>
                                <div className="d-flex justify-content-center mt-3" >
                                    {data?.images.map((image, index) => (
                                        <img
                                            key={index}
                                            src={image?.original}
                                            alt={`Indicator ${index}`}
                                            className={`indicator-image ${activeIndices[data?.id] === index ? 'active' : ''}`}
                                            onClick={() => handleSelect(index, data?.id)}
                                            style={{ cursor: 'pointer', width: '55px', height: '55px', padding: '10px', objectFit: 'cover', margin: '0 5px', border: (activeIndices[data?.id] === index) ? '1px solid #4579F5' : 'none', borderRadius: '15%' }}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="d-flex justify-content-around align-items-center mt-3 gap-5">
                                <div className="text-black fw-bold">
                                    <p>Phone mount</p>
                                    <p>${data?.phone_mount}</p>
                                </div>
                                <div className="d-flex gap-1 justify-content-start w-75">
                                    <Button variant="outline-dark" className=" w-35 fw-bold CartButton pe-4 ps-4 pt-2 pb-2" onClick={() => handleAddCart(data?.url)}>Buy / {data?.price_incl_tax}</Button>
                                    <Button variant="dark" className=" w-35 fw-bold rentButton pe-4 ps-4 pt-2 pb-2">Rent / {data?.rent}</Button>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
            <div>
                <PerfectScooter />
            </div>
        </div>
    );
};

export default Buy;


