import { combineReducers } from "redux";
import signUpReducer from "./SignUp/reducer";
import countriesReducer from './Countries/reducer'
import commonReducer from './common/reducer'
import checkoutReducer from "./checkout/reducer";
import userReducer from "./user/reducer";
import cartReducer from "./cart/reducer";
import signInReducer from "./signIn/reducer";
import paymentIntentReducer from "./paymentIntent/reducer";
export default combineReducers({
    signUpReducer,
    countriesReducer,
    commonReducer,
    checkoutReducer,
    userReducer,
    cartReducer,
    signInReducer,
    paymentIntentReducer
})