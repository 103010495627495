import { takeLatest, put } from "redux-saga/effects";
import {
    SIGN_UP,
    SIGN_UP_SUCESS,
    SIGN_UP_ERROR,
} from "./constant";
import url from 'services/urls.json';
import { postMethod} from "services/apiServices";

function* postSignUpApi(action) {

    try {
        console.log(action,"13")
        const response = yield postMethod(url.signUp, action.data);
        if (response.status === 200 || response.status === 201) {
            yield put({ type: SIGN_UP_SUCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction(response);
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }

        } else {
            yield put({ type: SIGN_UP_ERROR, payload: response });
            if (action.external && action.external.toast) {
                action.external.handleToastError(response);
            }
        }

    } catch (error) {
        yield put({ type: SIGN_UP_ERROR, payload: error });
    }
}

function* singUpSaga() {
    yield takeLatest(SIGN_UP, postSignUpApi);
}

export default singUpSaga;