import React, { useEffect, useState } from "react";
import { Container, Row, Col, Stack, Button } from 'react-bootstrap';
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import 'pages/OurVision/OurVision.scss'
const OurVision = () => {


    return (
        <div style={{ fontFamily: "Manrope, sans-serif" }} className="ourVision">
            <Container>
                <Row className="justify-content-between">
                    <Col md={4}>
                        <div className="mt-5 pt-5 mb-0 pb-0">
                            <h1 className="heading text-black relative pt-5 mt-5">Our vision</h1>
                            <p className="visionPara text-black">Emphasis needs to be put on: 1 in 10 scooters donated to kids who need it most.
                                Until we reach the goal of 1 million. Then we will continue to see how we can help the world even more</p>
                        </div>
                    </Col>
                    <Col md={7}>
                        <div>
                            <iframe src="https://www.youtube.com/embed/D0UnqGm_miA" height="400px" width="100%" title="Dummy Video For Website" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
                                style={{ borderRadius: '15px' }}></iframe>

                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-between mt-5 pt-5">
                    <Col md={6}>
                        <div>
                            <img src="https://metrorides.theclientdemos.com/assets/images/a2.png" alt="img" className="w-100" />
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="mt-4 pt-4">
                            <h1 className="heading text-black">We keep things ECO-Friendly, as well  as contributing to life changing causes</h1>
                            <p className="visionPara text-black">We will work directly with School Boards, Metro Communities, and Students personally.
                                Ensuring those in need receive an effective form of transportation, new or fresh refurbished rides.
                                A win for the carbon footprint, and a great use of resources.
                                It is our goal to remain carbon positive as long as we can serve you. </p>
                        </div>
                    </Col>
                </Row>
            </Container>


            <div>
                <PerfectScooter />
            </div>
        </div>
    )
}

export default OurVision