import { COMMON_DETAILS, COMMON_DETAILS_ERROR, COMMON_DETAILS_SUCCESS } from "./constant";

const data = {
    cart: ""
}

const commonReducer = (state = data, action) => {

    switch (action.type) {
        case COMMON_DETAILS:
            return { ...state,cart:action.data,loading:true};
        case COMMON_DETAILS_ERROR:
            return { ...state, error: action.error, loading: false };
        case COMMON_DETAILS_SUCCESS:
            return { ...state, cart: action.data,  loading: false };
        default:
            return state;
    }
}
export default commonReducer