import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Buy from "../pages/Buy/Buy";
import Cart from "../pages/Cart/Cart";
import Store from "../pages/Store/Store";
import OurVision from "../pages/OurVision/OurVision";
import Metro from "../pages/Metro/Metro";
import Compare  from "../pages/Compare/Compare";
import SignIn from 'pages/SignIn/SignIn';
import SignUp from 'pages/SignUp/SignUp';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import Home from 'pages/Home/Home';
import SetPassoword from 'pages/setPassword/setPassword';
import Checkout from 'pages/checkout/checkout';
import Delivery from 'pages/checkout/card/card'
const AppRoutes = () => {

    const privateRoutes = [
        {
            path: "/Compare",
            element: <Compare />
        },
        {
            path: "/Buy",
            element: <Buy />
        },
        {
            path: "/Cart",
            element: <Cart />
        },
        {
            path: "/Store",
            element: <Store />
        },
        {
            path: "/OurVision",
            element: <OurVision />
        },
        {
            path: "/Metro",
            element: <Metro />
        },
        {
            path: "/SignIn",
            element: <SignIn />
        },
        {
            path: "/SignUp",
            element: <SignUp />
        },
        {
            path: "/ForgotPassword",
            element: <ForgotPassword />
        },
        {
            path:"",
            element:<Home/>
        },
        {
            path:"/SetPassword",
            element:<SetPassoword/>
        },
        {
            path:"/checkout",
            element:<Checkout/>
        },
        {
            path:"/delivery",
            element:<Delivery/>
        }

    ]
    return (
        <Routes>
           {privateRoutes.map((item) => (
                <Route key={item.path} path={item.path} element={item.element} />
            ))}
             <Route path="" element={<Home/>} /> 
      
      </Routes>
    )
}
export default AppRoutes