import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FloatingLabel, Button, Stack, } from "react-bootstrap";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import 'pages/Compare/Compare.scss';
import url from 'services/urls.json';
import { getMethod, getMethodWithToken } from "services/apiServices";
import { CleaningServices } from "@mui/icons-material";
import { ClearTokenRedirectLogin } from "context/context";
import { useNavigate } from "react-router-dom";

const Compare = () => {
    const navigate = useNavigate()

    const [options, setOptions] = useState([])
    const [firstId, setFirstId] = useState("")
    const [secondId, setSecondId] = useState("")
    const [products, setProducts] = useState([])
    const [features, setFeatures] = useState([])

    const handleDropdown = async () => {
        let apiUrl = `${url.buy}?catagory=scooter&paginate=false&page_limit=0`;
        let response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        console.log(response, "22")
        if (response.status === 200 || response.status === 201) {
            console.log(response.data, "23")
            setOptions(response.data)
        }
        else if (response.status === 401 || response.status === 403) {
            ClearTokenRedirectLogin(navigate)
        }
    }
    const handleCompare = async () => {
        if (firstId === '' && secondId === '') {
            let apiUrl = url.compare;
            let response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
            if (response.status === 200 || response.status === 201) {
                setProducts(response.data)
                setFirstId(response.data[0].id);
                setSecondId(response.data[1].id);
                response?.data.map((data, index) => {
                    setFeatures(data?.attributes)

                })
            }
            else if (response.status === 401 || response.status === 403) {
                ClearTokenRedirectLogin(navigate)
            }
        }
        else {
            let apiUrl = `${url.compare}?product_first=${firstId}&product_second=${secondId}`
            let response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
            if (response.status === 200 || response.status === 201) {
                setProducts(response.data)
                response?.data.map((data, index) => {
                    setFeatures(data?.attributes)

                })

            }
            else if (response.status === 401 || response.status === 403) {
                ClearTokenRedirectLogin(navigate)
            }
        }

    }

    const handleFirstProduct = (e) => {
        setFirstId(e.target.value)
    }

    const handleSecondProduct = (e) => {
        setSecondId(e.target.value);
    }

    console.log(firstId, secondId)
    useEffect(() => {
        handleDropdown()

    }, [])
    useEffect(() => {
        handleCompare()
    }, [firstId, secondId])


    console.log(firstId, secondId,"75")

    return (
        <div style={{ fontFamily: "Manrope, sans-serif" }} className="compare">
            <Container>
                <Row>
                    <Col md={4}>
                        <h1 style={{ fontWeight: '600' }}>Compare</h1></Col>
                    <Col md={8}>
                        <div className="form_box d-flex gap-2">
                            <Row style={{ width: '100%' }}>
                                <Col md={6} xs={6}><FloatingLabel controlId="floatingSelect" label="Scooter #1" className="label labelWidth" style={{ fontSize: '12px' }} >
                                    <Form.Select aria-label="Floating label select example" value={firstId} className="backgroundForm" style={{ borderRadius: '20px', fontWeight: '600' }} onChange={handleFirstProduct}>
                                        {options.length>0 && options.map((data, index) => (
                                            <option value={data?.id} style={{ fontWeight: '600' }}>{data.title.trim().split(/\s+/).slice(0, 3).join(' ')}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel></Col>
                                <Col md={6} xs={6}><FloatingLabel controlId="floatingSelect" label="Scooter #2" className="label labelWidth" style={{ fontSize: '12px' }} >
                                    <Form.Select aria-label="Floating label select example" value={secondId} className="backgroundForm" style={{ borderRadius: '20px', fontWeight: '600' }} onChange={handleSecondProduct}>
                                        {options.length>0 && options.map((data, index) => (
                                            <option value={data?.id} style={{ fontWeight: '600' }} >{data.title.trim().split(/\s+/).slice(0, 3).join(' ')}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel></Col>

                            </Row>

                        </div></Col>
                </Row>
                <Row className="mt-3 mb-5 pb-5">
                    <Col md={12} lg={4}>
                        <div className="mt-5 pt-5 featureTag" style={{ visibility: 'hidden' }}><img src="https://metrorides.theclientdemos.com/assets/images/p.png" className="mx-auto" /></div>
                        <div className="mt-2 pt-5" >
                            {features && features.map((data) => (
                                <div className=" d-flex align-baseline gap-2" >
                                   <img src={data?.icon} style={{width:'15px',height:'15px',color:'#c5c5c5'}}/>
                                    <p className="fw-bold">{data?.name}</p>
                                </div>
                            ))}
                        </div>
                    </Col>

                    {products && products.length > 0 && products.map((data, index) => (
                        data?.id == firstId &&
                        <Col md={6} lg={4}><div style={{height:'465px'}}>
                            <Stack direction="horizontal" gap={3}>
                                <div className="p-2 fw-bold">4/5</div>
                                <div className="p-2 ms-auto d-flex gap-2 fw-bold"><div ><FavoriteBorderIcon style={{ width: '20px', height: '20px' }} /></div> <div><ShoppingCartOutlinedIcon style={{ width: '20px', height: '20px' }} /></div></div>
                            </Stack>
                            <h1 className="fw-bold text-black mt-2 mb-4">{data?.title}</h1>
                            <div >
                                <img src={data?.images[0]?.original} className="mx-auto" style={{height:'300px'}} />
                            </div>

                        </div>
                            <div style={{ background: '#f8f9fa', borderRadius: '20px' }} className="bg-light px-3 pt-3 pb-3 mt-3 mb-2">
                                {data?.attributes && data?.attributes.map((item, index) => (
                                    <Stack direction="horizontal" gap={2} className="pb-3 justify-content-between ">
                                        <div className="fw-bold mb-0 mx-2">{item?.value}</div>
                                        <div className="d-flex gap-2">
                                            <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" />
                                            <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" />
                                            <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" />
                                            <img src="https://metrorides.theclientdemos.com/assets/images/gry.svg" />
                                            <img src="https://metrorides.theclientdemos.com/assets/images/gry.svg" />
                                        </div>
                                    </Stack>
                                ))}
                            </div>
                            <Stack direction="vertical" gap={3}>
                                <Button variant="outline-dark p-2 buyButton "> Buy /  ${data?.price_incl_tax}</Button>
                                <Button className="bg-dark rentButton buyButton p-2">Rent / ${data?.rent}</Button>
                            </Stack>
                        </Col>
                    ))}

                    {products && products.length > 0 && products.map((data, index) => (
                        data?.id == secondId &&
                        <Col md={6} lg={4}><div style={{height:'465px'}}>
                            <Stack direction="horizontal" gap={3}>
                                <div className="p-2 fw-bold">4/5</div>
                                <div className="p-2 ms-auto d-flex gap-2 fw-bold"><div ><FavoriteBorderIcon style={{ width: '20px', height: '20px' }} /></div> <div><ShoppingCartOutlinedIcon style={{ width: '20px', height: '20px' }} /></div></div>
                            </Stack>
                            <h1 className="fw-bold text-black mt-2 mb-4">{data?.title}</h1>
                            <div>
                                <img src={data?.images[0]?.original} className="mx-auto" style={{height:'300px'}} />
                            </div>

                        </div>
                            <div style={{ background: '#f8f9fa', borderRadius: '20px' }} className="bg-light px-3 pt-3 pb-3 mt-3 mb-2">
                                {data?.attributes && data?.attributes.map((item, index) => (
                                    <Stack direction="horizontal" gap={2} className="pb-3 justify-content-between ">
                                        <div className="fw-bold mb-0 mx-2">{item?.value}</div>
                                        <div className="d-flex gap-2">
                                            <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" />
                                            <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" />
                                            <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" />
                                            <img src="https://metrorides.theclientdemos.com/assets/images/gry.svg" />
                                            <img src="https://metrorides.theclientdemos.com/assets/images/gry.svg" />
                                        </div>
                                    </Stack>
                                ))}
                            </div>
                            <Stack direction="vertical" gap={3}>
                                <Button variant="outline-dark p-2 buyButton "> Buy /  ${data?.price_incl_tax}</Button>
                                <Button className="bg-dark rentButton buyButton p-2">Rent / ${data?.rent}</Button>
                            </Stack>
                        </Col>
                    ))}


                </Row>

            </Container>
        </div>
    )
}

export default Compare