import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FloatingLabel, Button, Card } from "react-bootstrap";
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import 'pages/Store/Store.scss';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import url from 'services/urls.json';
import { getMethod, getMethodWithToken, postMethodWithToken } from "services/apiServices";
import Pagination from "react-js-pagination";
import { htmlToText } from 'html-to-text';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import commonCallApi from "store/common/action";
import { COMMON_DETAILS } from "store/common/constant";
import cartCallApi from "store/cart/action";
import { CART } from "store/cart/constant";
import userCallApi from "store/user/action";
import { USER } from "store/user/constant";
import {toast} from "react-toastify"
import { ClearTokenRedirectLogin } from "context/context";

const itemsPerPageOptions = [
    { value: 10, label: '10' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 5, label: '5' },
];


function HtmlToPlainText({ htmlContent }) {
    const plainText = htmlToText(htmlContent, {
        wordwrap: 130
    });

    return <div>{plainText}</div>;
}

const Store = () => {
    const navigate = useNavigate()
    const [storeList, setStoreList] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [count, setCount] = useState(1);
    const [sortPrice, setSortPrice] = useState([]);
    const [category, setCategory] = useState([]);
    const [categoryValue, setCategoryValue] = useState('')
    const [price, setPrice] = useState('')
    const cartSelector = useSelector(state => state.commonReducer?.cart?.cart)
    const [totalQuantity, setTotalQuantity] = useState(cartSelector ?? 0);
   
    
    var [activePage, setPage] = useState(sessionStorage.getItem("currentPage") !== null ? parseInt(sessionStorage.getItem("currentPage")) : 1);
    

    useEffect(() => {
        handleSearchList();
    }, [itemsPerPage, price]);

    const handleItemsPerPageChange = (e) => {
        setPage(Number(e.target.value));
        setItemsPerPage(e.target.value)
    };

    const handleSortPrice = async () => {
        await getMethod(url.sortPrice).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setSortPrice(response.data)
            }
        })
    }

    const handleCategory = async () => {
        let apiUrl = `${url.categoryDropdown}?parent_slug=accessories`
        await getMethod(apiUrl).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setCategory(response.data)
            }
        })
    }
    const handlecategoryDropdwon = (e) => {
        setCategoryValue(e.target.value)
    }
    useEffect(() => {
        handleSortPrice()
        handleCategory()
    }, [])
    const handleSearchList = async () => {
        setPage(1);
        let apiUrl = `${url.buy}?catagory=accessories&paginate=true&page_limit=${itemsPerPage}&sort${price}`;
        let response;
        response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        if (response.status === 200 || response.status === 201) {
            setStoreList(response.data.results);
            setCount(response.data ? response.data.count : "");
        }
        else if(response.status === 401 || response.status === 403){
            ClearTokenRedirectLogin(navigate)
        }
    }
    const handlePageChange = async (pageNumber) => {
        let apiUrl = `${url.buy}?catagory=accessories&page=${pageNumber}&paginate=true&page_limit=${itemsPerPage}&sort=${price}`;
        setPage(pageNumber);
        sessionStorage.setItem("currentPage", pageNumber);
        let response;
        response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        setStoreList(response.data && response.data.results);

    };
    const handlePrice = (e) => {
        setPrice(e.target.value)
    }
    const dispatch = useDispatch()
    const handleAddCart = async (product) => {

        let body = {
            "url": product,
            "quantity": 1
        }

        await postMethodWithToken(url.addCart, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                const basket = response.data.lines.split('/api/v1/')[1]
                toast.success("Item Added to cart")
               dispatch(userCallApi(USER,{},{run:true,
                handleFunction:(res)=>handleCartCount(res?.data?.basket_id)
               }))
                
                localStorage.setItem('cartAPI', basket)
                localStorage.setItem("clearAllCart",response.data.url)
            }
            else if (response.status === 406){
                toast.error("Out Of Stock")
            }
        })

    }
   

    const handleCartCount = (basketId) => {
        dispatch(cartCallApi(CART, {}, {
          url:`ecom/baskets/${basketId}/lines`,
          run: true,
          handleFunction: (res) => handleSuccess(res)
        }))
      };
    
      const handleSuccess = (res) => {
        dispatch(commonCallApi(COMMON_DETAILS, { cart: res?.data?.[0]?.total_quantity }, { run: true }))
      };
    // const handleCart = async () => {
    //     await getMethodWithToken(localStorage.getItem('cartAPI')).then((response) => {
    //         if (response.status === 200 || response.status === 201) {

    //             let count = 0;

    //             response.data?.length > 0 && response.data.map((data) => (
    //                 count = count + data?.quantity
    //             ))

    //             setTotalQuantity(count);
    //         }
    //     })
    // }

    // useEffect(() => {
    //     handleCart()
    // }, [])
    return (
        <div style={{ fontFamily: "Manrope, sans-serif" }} className="store">
            <Container className="pt-1 mb-5 mt-1">
                <Row className="align-items-center">
                    <Col md={4}>
                        <h1 className="text-sm-start" style={{ fontWeight: '600' }}>Store</h1>
                    </Col>
                    <Col md={8}>
                        <div className="form_box d-flex gap-2">
                            <Row>
                                <Col>
                                    <FloatingLabel controlId="floatingSelectCategory" label="Categories" className="label labelWidth" style={{ fontSize: '12px' }} onChange={handlecategoryDropdwon}>
                                        <Form.Select aria-label="Floating label select example" className="backgroundForm" >
                                            {category && category.length > 0 && category.map((data, index) => (
                                                <option value={data?.slug}>{data?.name}</option>
                                            ))}


                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel controlId="floatingSelectSort" label="Sort By Price" className="label labelWidth">
                                        <Form.Select aria-label="Floating label select example" className="backgroundForm" onChange={handlePrice}>
                                            {sortPrice && sortPrice.length > 0 && sortPrice.map((data, index) => (
                                                <option value={data?.slug}>{data?.name}</option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel controlId="floatingSelectPerPage" label="Per Page" className="label pageFilter">
                                        <Form.Select aria-label="Floating label select example" className="backgroundForm" onChange={handleItemsPerPageChange}>
                                            {itemsPerPageOptions.map((option, index) => (
                                                <option key={index} value={option.value}>{option.label}</option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    {storeList.length > 0 && storeList.map((item, index) => (
                        <Col md={6} lg={6} xl={5} xxl={3} sm={12} key={index} className="storeProducts">
                            <Card style={{ border: 'none' }} className="cardProduct">
                                <div className="pt-4">
                                    <Card.Img variant="top" src={item?.images[1]?.original} style={{ width: '15rem', margin: 'auto', height: '20rem' }} />
                                </div>
                                <Card.Body>
                                    <Card.Title style={{ fontSize: '16px', fontWeight: '600' }} className="title">{item?.title?.trim().split(/\s+/).slice(0, 2).join(' ')}</Card.Title>
                                    <Card.Text  >
                                        <div className="description">
                                            <HtmlToPlainText htmlContent={item?.description} />
                                        </div>

                                        <div className="pt-2" style={{ fontWeight: '700', fontSize: '16px' }}>
                                            <p>${item?.price_incl_tax}
                                            {/* <span className="text-danger" style={{ textDecoration: 'line-through' }}> ${item?.price_excl_tax}</span> */}
                                            </p>
                                        </div>
                                    </Card.Text>
                                    <Button variant="outline-dark" className="rounded-3 p-2 fw-bold CartButton w-100" onClick={() => handleAddCart(item?.url)}>Add to Cart</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>




                <div className="mt-5 pt-5">
                    {count > 1 ? (
                        <Pagination
                            prevPageText={
                                <Button variant="outline-dark" className="w-35 fw-bold CartButton pe-4 ps-4 pt-2 pb-2"   disabled={count<itemsPerPage}>
                                    <KeyboardDoubleArrowLeftIcon />
                                    <span className="ms-2">Previous</span>
                                </Button>
                            }
                            nextPageText={
                                <Button variant="outline-dark" className="w-35 fw-bold CartButton pe-4 ps-4 pt-2 pb-2" disabled={count<itemsPerPage}>
                                    <span className="ms-2">Next</span>
                                    <KeyboardDoubleArrowRightIcon />
                                </Button>
                            }
                            activePage={activePage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={count}
                            onChange={handlePageChange}
                            marginPagesDisplayed={0}
                            hideFirstLastPages={true}
                            pageRangeDisplayed={0}
                            itemClass="page-item"
                        />
                    ) : null}
                </div>

            </Container>
            <div className="mb-5 pb-4">
                <PerfectScooter />
            </div>
        </div>
    );
};

export default Store;
