import React from "react";
import { Col, Container, Row, Button } from 'react-bootstrap';
import 'pages/Metro/featureMetro/featureMetro.scss'

const FeatureMetro =()=>{
    const array = [
        {img:'https://metrorides.theclientdemos.com/assets/images/i1.png' , head: 'Roadside assistance',para:'Select and customize'},
        {img:'https://metrorides.theclientdemos.com/assets/images/i2.png' , head: 'Creating routes' , para:'Select and customize your model for an unbeatable'},
        {img:'https://metrorides.theclientdemos.com/assets/images/i3.png' , head: 'Warranty' , para:'Select and customize'},
        {img:'https://metrorides.theclientdemos.com/assets/images/i4.png' , head: 'Delivery' , para:'Select and customize'},
        {img:'https://metrorides.theclientdemos.com/assets/images/i5.png' , head: 'Delivery' , para:'Select and customize'}
    ]

    return (
        <div style={{ fontFamily: "Manrope, sans-serif" }} className="featureMetro mb-5">
            <Container>
                <Row>
                    <Col md={12}>
                        <h1 className="text-center mb-5" style={{fontWeight:'600'}}>What will you get?</h1>
                    </Col>
                    {array && array.map((data,index)=>(
                        <Col  md={index === 0 ? 3 : index === 1 ? 9 : index === 2 ? 6 : index === 3 ? 3 : 3} >
                        <div className="relative mt-4">
                        <img src={data?.img} className="w-100 rounded-4 " style={{height:'244px'}}/>
                            <div className="imageText text-white p-4">
                                <h3 className="mt-5 pt-5">{data?.head}</h3>
                                <p className="mb-0">{data?.para}</p>
                            </div>
                        </div>
                            
                        </Col>
                    ))}
                   
                </Row>
            </Container>
        
        </div>
    )
}

export default FeatureMetro