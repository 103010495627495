import { React, useState, useEffect } from "react";
import Questions from 'pages/Home/Questions/Questions';
import { Col, Container, Row, Button } from 'react-bootstrap';
import 'pages/Metro/Metro.scss';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import FeatureMetro from './featureMetro/featureMetro';
const Metro = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <div style={{ fontFamily: "Manrope, sans-serif" }} >
            <div className="metro relative">
                <Container className="pb-5">
                    <Row>
                        <Col md={6} className="pb-5">
                            <div className="metroContent ">
                                <div className="text-black absolute heading">
                                    <h1 className="metroHeading" style={{ fontWeight: '600' }}>Metro+</h1>
                                    <p className="paraImage" style={{ fontWeight: '500' }}>Unlock the ultimate e-scooter experience with our monthly subscription, offering seamless integration.
                                        Effortlessly discover local gems and coordinate ride-sharing with friends, because every journey is better when shared</p>
                                    <Button variant="dark" className="mt-4 rounded-5">Get started <KeyboardArrowRightIcon /></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <FeatureMetro/>
            </div>
            <div className="mt-5 pt-2">
                <div className="mt-5 mb-5 text-center pb-3">
                    <h1 style={{fontWeight:'600',fontSize:'32px'}} >Feedback from subscribers</h1>
                </div>
                <Questions />
            </div>
            <div>
                <PerfectScooter />
            </div>
        </div>

    )
}

export default Metro