import { React, useEffect, useState } from "react";
import { Button, Row, Col, Container, FormControl, InputGroup } from "react-bootstrap";
import moon from 'assets/images/moon_stars.webp'
import { useNavigate, useLocation } from "react-router-dom";
import "pages/Cart/Cart.scss";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import url from 'services/urls.json';
import { useDispatch, useSelector } from "react-redux";
import commonCallApi from "store/common/action";
import { COMMON_DETAILS } from "store/common/constant";
import { getMethodWithToken, deleteMethodWithToken, updateMethodWithToken } from "services/apiServices";
import checkoutCallApi from "store/checkout/action";
import { CHECKOUT } from "store/checkout/constant";
import cartCallApi from "store/cart/action";
import { CART } from "store/cart/constant";
import userCallApi from "store/user/action";
import { USER } from "store/user/constant";
import { ClearTokenRedirectLogin } from "context/context";

const Cart = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const [cartArray, setCartArray] = useState([])
    const [productDetail, setProductDetail] = useState([])
    const [product, setProduct] = useState('')
    const cartSelector = useSelector(state => state.commonReducer?.cart?.cart);
    const [cartItem, setCartItem] = useState(cartSelector ?? 0);

    const [totalQuantity, setTotalQuantity] = useState(0);
    const [value, setValue] = useState(0);

    const basketId = useSelector(state=>state?.userReducer?.payload?.data?.basket_id)
    const handleIncrement = (quantity, product) => {
        setCartItem(quantity + 1);
        setProduct(product)
        setValue(quantity + 1);
        handleQuantity(product.split('api/v1/')[1], quantity + 1)
    };

    const handleDecrement = (quantity, product) => {
        if(quantity>1){
            setCartItem(quantity - 1)
            setValue(quantity - 1)
            handleQuantity(product.split('api/v1/')[1], quantity - 1)
        }
        else {
            setCartItem(quantity)
            setValue(quantity);
        } 
        setProduct(product)
       
        
    };

    const handleQuantity = async (id, quantity) => {
        const cart = localStorage.getItem('cartAPI')
        const apiUrl = `${id}`
        let body = {
            quantity: quantity
        }
        await updateMethodWithToken(apiUrl, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                handleCart()
            }
        })
    }
    const handleChange = (e) => {
        const newValue = parseInt(e.target.value, 10);
        if (!isNaN(newValue) && newValue >= 0) {
            setValue(newValue);
        }
    };
    const handleAddMore = () => {
        navigate('/store')
    }
    const handleCart = (res) => {
        dispatch(cartCallApi(CART, {}, {
          url:res?.data?.basket_id===null?`ecom/baskets/`:`ecom/baskets/${basketId}/lines`,
          run: true,
          handleFunction: (res) => handleCartCount(res)
        }))
      };

  
    const handleCartCount = (response) => {
        dispatch(commonCallApi(COMMON_DETAILS, { cart: response?.data?.[0]?.total_quantity }, { run: true }))
        setCartArray(response.data)
        setValue(response.data.quantity)
        response.data?.length > 0 && response.data.map((data) => (
            handleProductDetail(data?.product.split('api/v1/')[1])
        ))
        
        let amount = 0 ;
        response.data?.length > 0 && response.data.map((data) => {
            return (
               
                amount = amount + Number(data?.price_incl_tax)
            )
            
    });
    
        localStorage.setItem("totalAmount",amount)


    }

  
    const itemSet = new Set();
    const handleProductDetail = async (api) => {
        await getMethodWithToken(api).then((response) => {

            if (response.status === 200 || response.status === 201) {
                let item = response.data
                const itemString = JSON.stringify(item);
                if (!itemSet.has(itemString)) {
                    itemSet.add(itemString);
                    setProductDetail(Array.from(itemSet).map(str => JSON.parse(str)))
                }
            }
            else if (response.status === 401 || response.status === 403) {
                ClearTokenRedirectLogin(navigate)
            }

        })

    }
    const handleClearCart = async (quantity, api) => {
        await deleteMethodWithToken(api).then((response) => {
            if (response.status === 200 || response.status === 204) {
                if (cartItem < 0) {
                    dispatch(commonCallApi(COMMON_DETAILS, { 'cart': 0 }, { run: true }));

                }
                else {
                    dispatch(commonCallApi(COMMON_DETAILS, { 'cart': cartItem - quantity }, { run: true }));
                }
                handleCart(localStorage.getItem('cartAPI'))
            }
        })
    }
    
    const handleClearAllCart = async () => {
        
        const api = localStorage.getItem("clearAllCart");
        await deleteMethodWithToken(api).then((response) => {
            if (response.status === 200 || response.status === 204) {
                dispatch(commonCallApi(COMMON_DETAILS, { 'cart': 0 }, { run: true }));
                dispatch(userCallApi(USER , {},{run:true,
                    handleFunction:(res)=>handleCart(res)
                }))
                
                localStorage.removeItem('cartAPI');
              
            }
        })
    }

    const handleCheckout = () => {
        navigate('/checkout')
    }

    useEffect(() => {
        handleCart()
    }, [])


    return (
        <div className="cart p-3" style={{ fontFamily: "Manrope, sans-serif" }}>
            {cartArray && cartArray?.length > 0 ? (
                <Container fluid>
                    <p className="text-black boldpara" >Your purchases</p>
                    {cartArray.map((data, index) => (
                        <div className="filledCart mb-4">
                            <Row className="product_cart_box p-2 d-flex justify-content-between align-items-center mb-2">
                                {productDetail?.length > 0 && productDetail.map((product, index) => (
                                    data?.product === product?.url &&
                                    <Col md={4} className="d-flex justify-content-start align-items-center py-3">
                                        <img src={product?.images[0]?.original} alt="cart" className="w-7" />
                                        <div className="mx-3 d-flex">
                                            <p className="text-black mb-1 boldpara">{product?.title.trim().split(/\s+/).slice(0, 1).join(' ')}</p>
                                            <span className="buy p-1 text-white px-2 font-12 mx-2 rounded-pill">Buy</span>
                                            <span className="boldpara">${product?.price_incl_tax}</span>
                                        </div>
                                    </Col>
                                ))}
                                <Col md={4} className="d-flex" style={{ gap: '10px' }}>
                                    <input type="number" value={product == data?.product && value !== 0 ? value : data?.quantity} min={1} onChange={handleChange} className="border border-1 rounded-4 text-center" style={{ width: '5rem' }} />
                                    <Button variant="secondary" onClick={() => handleDecrement(data?.quantity, data?.url)} style={{ width: '2rem' }} disabled={product ==data?.url && value <= 1}>-</Button>
                                    <Button variant="secondary" onClick={() => handleIncrement(data?.quantity, data?.url)} style={{ width: '2rem' }}>+</Button>
                                </Col>
                                <Col md={3}>
                                    <p className="text-black boldpara" >${data?.price_excl_tax}</p>
                                </Col>
                                <Col md={1}>
                                    <CloseIcon style={{ color: 'grey', width: '44px', height: '44px',cursor:'pointer' }} onClick={() => handleClearCart(data?.quantity, data?.url)} />
                                </Col>
                            </Row>
                          
                            

                        </div>
                    ))}
                    <div className="add_cart_box mb-2 p-3 d-flex justify-content-center align-items-center" onClick={handleAddMore}>
                        <AddIcon style={{ width: '20px' }} />
                        <p className="addMore">Add more</p>
                    </div>
                   
                    <div>
                                <div className="d-flex justify-content-between align-items-center mt-5">
                                    <p className="text-black boldpara">Sub Total</p>
                                    <p className="text-black boldpara">${Number(localStorage.getItem("totalAmount"))}</p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="text-black boldpara">Tax</p>
                                    <p className="text-black boldpara">$0</p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="text-black boldpara">Shipping</p>
                                    <p className="text-black boldpara">Free Shipping</p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="text-black boldpara">Total</p>
                                    <p className="text-black boldpara">${Number(localStorage.getItem("totalAmount"))}</p>
                                </div>
                            </div>
                            <Col md={3} className="mt-2">
                        <Button variant="dark" className=" w-100 fw-bold rentButton pe-4 ps-4 pt-2 pb-2" onClick={handleCheckout}>Checkout</Button>
                    </Col>
                    
                                <Col md={3} className="mt-2">
                                    <Button variant="dark" className=" w-100 fw-bold rentButton pe-4 ps-4 pt-2 pb-2" onClick={handleClearAllCart}>Clear Cart</Button>
                                </Col>
                          

                </Container>

            )
                : (
                    <div>
                        <div className="emptyCart text-center pt-5 mt-5 d-flex justify-content-center flex-column align-items-center">
                            <img src={moon} style={{ width: '30px', height: '30px' }} />
                            <h6 className="text-black mt-4 pb-3 fontCart" >Your shopping cart is empty</h6>
                            <Button variant="dark" className="mt-2 w-100 fontCart buttons" onClick={() => navigate("/Store")} >Go to models</Button>
                            <Button variant="dark" className="mt-2 w-100 fontCart buttons" >Checkout</Button>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default Cart