import { React, useState, useEffect } from "react";
import { Container, Row, Col,Button } from "react-bootstrap";

const ModerApp = () =>{


    return (
        <div style={{fontFamily:"Manrope, sans-serif"}}>
              <Container className="pt-5 pb-4  mt-5 mb-5" >
                        <Row className="mt-5 align-items-center">
                           
                            <Col md={6} className="text-left">
                            <img src="https://metrorides.theclientdemos.com/assets/images/a5.png" className="w-100"/>
                               </Col>
                            <Col md={6} className="text-left">
                            <h1 style={{fontWeight:'600'}}>A convenient modern <br/> App  for your scooter </h1>
                              <Button variant="dark" className="mt-4 rounded-5">Coming soon...</Button>

                            </Col>

                        </Row>
                    </Container>
        </div>
    )
}
export default ModerApp