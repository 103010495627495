import { React, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import "../Questions/Questions.scss"
import { Col, Container, Row } from 'react-bootstrap';
import url from 'services/urls.json';
import { getMethod, getMethodWithToken } from 'services/apiServices';
import { ClearTokenRedirectLogin } from 'context/context';
import { useNavigate } from 'react-router-dom';

export default function Questions() {

    const navigate=useNavigate()
    const [questions , setQuestions]=useState([])
    const [id, setId] = useState(1);
    useEffect(()=>{
        handleQuestion()
    },[])

    const handleQuestion = async()=>{
        let apiUrl = url.question
        let response = localStorage.getItem('token') ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        if(response.status === 200 || response.status === 201){
            setQuestions(response.data)
        }
        else if (response.status === 401 || response.status === 403) {
            ClearTokenRedirectLogin(navigate)
        }
    }
    const handleChange = (id) => {
        setId(id)
    };
    return (
        <div className='questions text-left' style={{ fontFamily: "Manrope, sans-serif" }}>
            <Container>
                <Row>
                    <Col md={4} >
                        <h1 style={{ fontWeight: '600' }} className='mb-3'>Frequently  Asked  Questions</h1>
                    </Col>
                    <Col md={8}> {questions && questions.map((data, index) => (
                        <Accordion expanded={id === data?.id ? true : false} onChange={() => handleChange(data?.id)} className={`accordion-item ${id === data.id ? 'expanded' : ''} ${data.id === 1 ? 'no-border' : ''}`}
                        >
                            <AccordionSummary
                                expandIcon={
                                    id === data?.id ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />
                                }
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography style={{ fontWeight: '600', padding: '10px 0px', fontSize: '18px', color: '#000' }}>{data?.questionText}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {data?.answer && data?.answer.length>0 && data?.answer.map((ans , index)=>(
                                     <Typography>
                                     {ans?.answerText}
                                 </Typography>
                                ))}
                               
                            </AccordionDetails>
                        </Accordion>
                    ))}</Col>
                </Row>
            </Container>

        </div>
    );
}
